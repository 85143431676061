/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DuplicationMeetingForm,
  MeetingForm,
  MeetingFormField,
  MeetingFormSubmission,
  NotNestedMeetingForm,
  PatchedMeetingForm,
  PatchedMeetingFormField,
  PatchedMeetingFormSubmission,
  PublishMeetingForm,
} from '../models/index';
import {
    DuplicationMeetingFormFromJSON,
    DuplicationMeetingFormToJSON,
    MeetingFormFromJSON,
    MeetingFormToJSON,
    MeetingFormFieldFromJSON,
    MeetingFormFieldToJSON,
    MeetingFormSubmissionFromJSON,
    MeetingFormSubmissionToJSON,
    NotNestedMeetingFormFromJSON,
    NotNestedMeetingFormToJSON,
    PatchedMeetingFormFromJSON,
    PatchedMeetingFormToJSON,
    PatchedMeetingFormFieldFromJSON,
    PatchedMeetingFormFieldToJSON,
    PatchedMeetingFormSubmissionFromJSON,
    PatchedMeetingFormSubmissionToJSON,
    PublishMeetingFormFromJSON,
    PublishMeetingFormToJSON,
} from '../models/index';

export interface MeetingsFormsCreateRequest {
    meetingUuid: string;
    notNestedMeetingForm: Omit<NotNestedMeetingForm, 'id'|'form_fields'|'created_at'|'updated_at'>;
}

export interface MeetingsFormsDestroyRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsFormsDuplicateCreateRequest {
    meetingUuid: string;
    uuid: string;
    duplicationMeetingForm?: DuplicationMeetingForm;
}

export interface MeetingsFormsFieldsCreateRequest {
    formUuid: string;
    meetingUuid: string;
    meetingFormField: Omit<MeetingFormField, 'id'>;
}

export interface MeetingsFormsFieldsDestroyRequest {
    formUuid: string;
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsFormsFieldsListRequest {
    formUuid: string;
    meetingUuid: string;
    ordering?: string;
    search?: string;
}

export interface MeetingsFormsFieldsPartialUpdateRequest {
    formUuid: string;
    meetingUuid: string;
    uuid: string;
    patchedMeetingFormField?: Omit<PatchedMeetingFormField, 'id'>;
}

export interface MeetingsFormsFieldsRetrieveRequest {
    formUuid: string;
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsFormsFieldsUpdateRequest {
    formUuid: string;
    meetingUuid: string;
    uuid: string;
    meetingFormField: Omit<MeetingFormField, 'id'>;
}

export interface MeetingsFormsListRequest {
    meetingUuid: string;
    ordering?: string;
    search?: string;
}

export interface MeetingsFormsPartialUpdateRequest {
    meetingUuid: string;
    uuid: string;
    patchedMeetingForm?: Omit<PatchedMeetingForm, 'id'|'form_fields'|'created_at'|'updated_at'>;
}

export interface MeetingsFormsPublicListRequest {
    meetingUuid: string;
    ordering?: string;
    search?: string;
}

export interface MeetingsFormsPublicRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsFormsPublishCreateRequest {
    meetingUuid: string;
    uuid: string;
    publishMeetingForm?: PublishMeetingForm;
}

export interface MeetingsFormsRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsFormsSubmissionsCreateRequest {
    formUuid: string;
    meetingUuid: string;
    meetingFormSubmission: Omit<MeetingFormSubmission, 'id'|'submitted_at'|'url'>;
}

export interface MeetingsFormsSubmissionsDestroyRequest {
    formUuid: string;
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsFormsSubmissionsListRequest {
    formUuid: string;
    meetingUuid: string;
    ordering?: string;
    search?: string;
}

export interface MeetingsFormsSubmissionsPartialUpdateRequest {
    formUuid: string;
    meetingUuid: string;
    uuid: string;
    patchedMeetingFormSubmission?: Omit<PatchedMeetingFormSubmission, 'id'|'submitted_at'|'url'>;
}

export interface MeetingsFormsSubmissionsRetrieveRequest {
    formUuid: string;
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsFormsSubmissionsUpdateRequest {
    formUuid: string;
    meetingUuid: string;
    uuid: string;
    meetingFormSubmission: Omit<MeetingFormSubmission, 'id'|'submitted_at'|'url'>;
}

export interface MeetingsFormsUnpublishCreateRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsFormsUpdateRequest {
    meetingUuid: string;
    uuid: string;
    notNestedMeetingForm: Omit<NotNestedMeetingForm, 'id'|'form_fields'|'created_at'|'updated_at'>;
}

/**
 * 
 */
export class FormsApi extends runtime.BaseAPI {

    /**
     * TODO
     */
    async meetingsFormsCreateRaw(requestParameters: MeetingsFormsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedMeetingForm>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsCreate().'
            );
        }

        if (requestParameters['notNestedMeetingForm'] == null) {
            throw new runtime.RequiredError(
                'notNestedMeetingForm',
                'Required parameter "notNestedMeetingForm" was null or undefined when calling meetingsFormsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedMeetingFormToJSON(requestParameters['notNestedMeetingForm']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedMeetingFormFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsFormsCreate(requestParameters: MeetingsFormsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedMeetingForm> {
        const response = await this.meetingsFormsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async meetingsFormsDestroyRaw(requestParameters: MeetingsFormsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsDestroy().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsFormsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async meetingsFormsDestroy(requestParameters: MeetingsFormsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsFormsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Dupliquer un formulaire
     */
    async meetingsFormsDuplicateCreateRaw(requestParameters: MeetingsFormsDuplicateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DuplicationMeetingForm>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsDuplicateCreate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsFormsDuplicateCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/{uuid}/duplicate/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DuplicationMeetingFormToJSON(requestParameters['duplicationMeetingForm']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DuplicationMeetingFormFromJSON(jsonValue));
    }

    /**
     * Dupliquer un formulaire
     */
    async meetingsFormsDuplicateCreate(requestParameters: MeetingsFormsDuplicateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DuplicationMeetingForm> {
        const response = await this.meetingsFormsDuplicateCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsFormsFieldsCreateRaw(requestParameters: MeetingsFormsFieldsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingFormField>> {
        if (requestParameters['formUuid'] == null) {
            throw new runtime.RequiredError(
                'formUuid',
                'Required parameter "formUuid" was null or undefined when calling meetingsFormsFieldsCreate().'
            );
        }

        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsFieldsCreate().'
            );
        }

        if (requestParameters['meetingFormField'] == null) {
            throw new runtime.RequiredError(
                'meetingFormField',
                'Required parameter "meetingFormField" was null or undefined when calling meetingsFormsFieldsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/{form_uuid}/fields/`.replace(`{${"form_uuid"}}`, encodeURIComponent(String(requestParameters['formUuid']))).replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingFormFieldToJSON(requestParameters['meetingFormField']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingFormFieldFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsFormsFieldsCreate(requestParameters: MeetingsFormsFieldsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingFormField> {
        const response = await this.meetingsFormsFieldsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsFormsFieldsDestroyRaw(requestParameters: MeetingsFormsFieldsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['formUuid'] == null) {
            throw new runtime.RequiredError(
                'formUuid',
                'Required parameter "formUuid" was null or undefined when calling meetingsFormsFieldsDestroy().'
            );
        }

        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsFieldsDestroy().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsFormsFieldsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/{form_uuid}/fields/{uuid}/`.replace(`{${"form_uuid"}}`, encodeURIComponent(String(requestParameters['formUuid']))).replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * TODO
     */
    async meetingsFormsFieldsDestroy(requestParameters: MeetingsFormsFieldsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsFormsFieldsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async meetingsFormsFieldsListRaw(requestParameters: MeetingsFormsFieldsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MeetingFormField>>> {
        if (requestParameters['formUuid'] == null) {
            throw new runtime.RequiredError(
                'formUuid',
                'Required parameter "formUuid" was null or undefined when calling meetingsFormsFieldsList().'
            );
        }

        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsFieldsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/{form_uuid}/fields/`.replace(`{${"form_uuid"}}`, encodeURIComponent(String(requestParameters['formUuid']))).replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MeetingFormFieldFromJSON));
    }

    /**
     */
    async meetingsFormsFieldsList(requestParameters: MeetingsFormsFieldsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MeetingFormField>> {
        const response = await this.meetingsFormsFieldsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsFormsFieldsPartialUpdateRaw(requestParameters: MeetingsFormsFieldsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingFormField>> {
        if (requestParameters['formUuid'] == null) {
            throw new runtime.RequiredError(
                'formUuid',
                'Required parameter "formUuid" was null or undefined when calling meetingsFormsFieldsPartialUpdate().'
            );
        }

        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsFieldsPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsFormsFieldsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/{form_uuid}/fields/{uuid}/`.replace(`{${"form_uuid"}}`, encodeURIComponent(String(requestParameters['formUuid']))).replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedMeetingFormFieldToJSON(requestParameters['patchedMeetingFormField']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingFormFieldFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsFormsFieldsPartialUpdate(requestParameters: MeetingsFormsFieldsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingFormField> {
        const response = await this.meetingsFormsFieldsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsFormsFieldsRetrieveRaw(requestParameters: MeetingsFormsFieldsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingFormField>> {
        if (requestParameters['formUuid'] == null) {
            throw new runtime.RequiredError(
                'formUuid',
                'Required parameter "formUuid" was null or undefined when calling meetingsFormsFieldsRetrieve().'
            );
        }

        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsFieldsRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsFormsFieldsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/{form_uuid}/fields/{uuid}/`.replace(`{${"form_uuid"}}`, encodeURIComponent(String(requestParameters['formUuid']))).replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingFormFieldFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsFormsFieldsRetrieve(requestParameters: MeetingsFormsFieldsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingFormField> {
        const response = await this.meetingsFormsFieldsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsFormsFieldsUpdateRaw(requestParameters: MeetingsFormsFieldsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingFormField>> {
        if (requestParameters['formUuid'] == null) {
            throw new runtime.RequiredError(
                'formUuid',
                'Required parameter "formUuid" was null or undefined when calling meetingsFormsFieldsUpdate().'
            );
        }

        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsFieldsUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsFormsFieldsUpdate().'
            );
        }

        if (requestParameters['meetingFormField'] == null) {
            throw new runtime.RequiredError(
                'meetingFormField',
                'Required parameter "meetingFormField" was null or undefined when calling meetingsFormsFieldsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/{form_uuid}/fields/{uuid}/`.replace(`{${"form_uuid"}}`, encodeURIComponent(String(requestParameters['formUuid']))).replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingFormFieldToJSON(requestParameters['meetingFormField']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingFormFieldFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsFormsFieldsUpdate(requestParameters: MeetingsFormsFieldsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingFormField> {
        const response = await this.meetingsFormsFieldsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async meetingsFormsListRaw(requestParameters: MeetingsFormsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MeetingForm>>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MeetingFormFromJSON));
    }

    /**
     */
    async meetingsFormsList(requestParameters: MeetingsFormsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MeetingForm>> {
        const response = await this.meetingsFormsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsFormsPartialUpdateRaw(requestParameters: MeetingsFormsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingForm>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsFormsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedMeetingFormToJSON(requestParameters['patchedMeetingForm']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingFormFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsFormsPartialUpdate(requestParameters: MeetingsFormsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingForm> {
        const response = await this.meetingsFormsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async meetingsFormsPublicListRaw(requestParameters: MeetingsFormsPublicListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MeetingForm>>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsPublicList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms-public/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MeetingFormFromJSON));
    }

    /**
     */
    async meetingsFormsPublicList(requestParameters: MeetingsFormsPublicListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MeetingForm>> {
        const response = await this.meetingsFormsPublicListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsFormsPublicRetrieveRaw(requestParameters: MeetingsFormsPublicRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingForm>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsPublicRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsFormsPublicRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms-public/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingFormFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsFormsPublicRetrieve(requestParameters: MeetingsFormsPublicRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingForm> {
        const response = await this.meetingsFormsPublicRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Publier un formulaire
     */
    async meetingsFormsPublishCreateRaw(requestParameters: MeetingsFormsPublishCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublishMeetingForm>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsPublishCreate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsFormsPublishCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/{uuid}/publish/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PublishMeetingFormToJSON(requestParameters['publishMeetingForm']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublishMeetingFormFromJSON(jsonValue));
    }

    /**
     * Publier un formulaire
     */
    async meetingsFormsPublishCreate(requestParameters: MeetingsFormsPublishCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublishMeetingForm> {
        const response = await this.meetingsFormsPublishCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsFormsRetrieveRaw(requestParameters: MeetingsFormsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingForm>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsFormsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingFormFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsFormsRetrieve(requestParameters: MeetingsFormsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingForm> {
        const response = await this.meetingsFormsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsFormsSubmissionsCreateRaw(requestParameters: MeetingsFormsSubmissionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingFormSubmission>> {
        if (requestParameters['formUuid'] == null) {
            throw new runtime.RequiredError(
                'formUuid',
                'Required parameter "formUuid" was null or undefined when calling meetingsFormsSubmissionsCreate().'
            );
        }

        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsSubmissionsCreate().'
            );
        }

        if (requestParameters['meetingFormSubmission'] == null) {
            throw new runtime.RequiredError(
                'meetingFormSubmission',
                'Required parameter "meetingFormSubmission" was null or undefined when calling meetingsFormsSubmissionsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/{form_uuid}/submissions/`.replace(`{${"form_uuid"}}`, encodeURIComponent(String(requestParameters['formUuid']))).replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingFormSubmissionToJSON(requestParameters['meetingFormSubmission']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingFormSubmissionFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsFormsSubmissionsCreate(requestParameters: MeetingsFormsSubmissionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingFormSubmission> {
        const response = await this.meetingsFormsSubmissionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsFormsSubmissionsDestroyRaw(requestParameters: MeetingsFormsSubmissionsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['formUuid'] == null) {
            throw new runtime.RequiredError(
                'formUuid',
                'Required parameter "formUuid" was null or undefined when calling meetingsFormsSubmissionsDestroy().'
            );
        }

        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsSubmissionsDestroy().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsFormsSubmissionsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/{form_uuid}/submissions/{uuid}/`.replace(`{${"form_uuid"}}`, encodeURIComponent(String(requestParameters['formUuid']))).replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * TODO
     */
    async meetingsFormsSubmissionsDestroy(requestParameters: MeetingsFormsSubmissionsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsFormsSubmissionsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async meetingsFormsSubmissionsListRaw(requestParameters: MeetingsFormsSubmissionsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MeetingFormSubmission>>> {
        if (requestParameters['formUuid'] == null) {
            throw new runtime.RequiredError(
                'formUuid',
                'Required parameter "formUuid" was null or undefined when calling meetingsFormsSubmissionsList().'
            );
        }

        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsSubmissionsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/{form_uuid}/submissions/`.replace(`{${"form_uuid"}}`, encodeURIComponent(String(requestParameters['formUuid']))).replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MeetingFormSubmissionFromJSON));
    }

    /**
     */
    async meetingsFormsSubmissionsList(requestParameters: MeetingsFormsSubmissionsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MeetingFormSubmission>> {
        const response = await this.meetingsFormsSubmissionsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsFormsSubmissionsPartialUpdateRaw(requestParameters: MeetingsFormsSubmissionsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingFormSubmission>> {
        if (requestParameters['formUuid'] == null) {
            throw new runtime.RequiredError(
                'formUuid',
                'Required parameter "formUuid" was null or undefined when calling meetingsFormsSubmissionsPartialUpdate().'
            );
        }

        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsSubmissionsPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsFormsSubmissionsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/{form_uuid}/submissions/{uuid}/`.replace(`{${"form_uuid"}}`, encodeURIComponent(String(requestParameters['formUuid']))).replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedMeetingFormSubmissionToJSON(requestParameters['patchedMeetingFormSubmission']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingFormSubmissionFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsFormsSubmissionsPartialUpdate(requestParameters: MeetingsFormsSubmissionsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingFormSubmission> {
        const response = await this.meetingsFormsSubmissionsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsFormsSubmissionsRetrieveRaw(requestParameters: MeetingsFormsSubmissionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingFormSubmission>> {
        if (requestParameters['formUuid'] == null) {
            throw new runtime.RequiredError(
                'formUuid',
                'Required parameter "formUuid" was null or undefined when calling meetingsFormsSubmissionsRetrieve().'
            );
        }

        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsSubmissionsRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsFormsSubmissionsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/{form_uuid}/submissions/{uuid}/`.replace(`{${"form_uuid"}}`, encodeURIComponent(String(requestParameters['formUuid']))).replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingFormSubmissionFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsFormsSubmissionsRetrieve(requestParameters: MeetingsFormsSubmissionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingFormSubmission> {
        const response = await this.meetingsFormsSubmissionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsFormsSubmissionsUpdateRaw(requestParameters: MeetingsFormsSubmissionsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingFormSubmission>> {
        if (requestParameters['formUuid'] == null) {
            throw new runtime.RequiredError(
                'formUuid',
                'Required parameter "formUuid" was null or undefined when calling meetingsFormsSubmissionsUpdate().'
            );
        }

        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsSubmissionsUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsFormsSubmissionsUpdate().'
            );
        }

        if (requestParameters['meetingFormSubmission'] == null) {
            throw new runtime.RequiredError(
                'meetingFormSubmission',
                'Required parameter "meetingFormSubmission" was null or undefined when calling meetingsFormsSubmissionsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/{form_uuid}/submissions/{uuid}/`.replace(`{${"form_uuid"}}`, encodeURIComponent(String(requestParameters['formUuid']))).replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingFormSubmissionToJSON(requestParameters['meetingFormSubmission']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingFormSubmissionFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsFormsSubmissionsUpdate(requestParameters: MeetingsFormsSubmissionsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingFormSubmission> {
        const response = await this.meetingsFormsSubmissionsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Dépublier un formulaire
     */
    async meetingsFormsUnpublishCreateRaw(requestParameters: MeetingsFormsUnpublishCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsUnpublishCreate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsFormsUnpublishCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/{uuid}/unpublish/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Dépublier un formulaire
     */
    async meetingsFormsUnpublishCreate(requestParameters: MeetingsFormsUnpublishCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsFormsUnpublishCreateRaw(requestParameters, initOverrides);
    }

    /**
     * TODO
     */
    async meetingsFormsUpdateRaw(requestParameters: MeetingsFormsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedMeetingForm>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsFormsUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsFormsUpdate().'
            );
        }

        if (requestParameters['notNestedMeetingForm'] == null) {
            throw new runtime.RequiredError(
                'notNestedMeetingForm',
                'Required parameter "notNestedMeetingForm" was null or undefined when calling meetingsFormsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/forms/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedMeetingFormToJSON(requestParameters['notNestedMeetingForm']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedMeetingFormFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsFormsUpdate(requestParameters: MeetingsFormsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedMeetingForm> {
        const response = await this.meetingsFormsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
