/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EventVisibilityEnum } from './EventVisibilityEnum';
import {
    EventVisibilityEnumFromJSON,
    EventVisibilityEnumFromJSONTyped,
    EventVisibilityEnumToJSON,
    EventVisibilityEnumToJSONTyped,
} from './EventVisibilityEnum';
import type { EventTypeEnum } from './EventTypeEnum';
import {
    EventTypeEnumFromJSON,
    EventTypeEnumFromJSONTyped,
    EventTypeEnumToJSON,
    EventTypeEnumToJSONTyped,
} from './EventTypeEnum';
import type { NotNestedPublicActivity } from './NotNestedPublicActivity';
import {
    NotNestedPublicActivityFromJSON,
    NotNestedPublicActivityFromJSONTyped,
    NotNestedPublicActivityToJSON,
    NotNestedPublicActivityToJSONTyped,
} from './NotNestedPublicActivity';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedNotNestedEventWithNestedActivity
 */
export interface PatchedNotNestedEventWithNestedActivity {
    /**
     * 
     * @type {string}
     * @memberof PatchedNotNestedEventWithNestedActivity
     */
    readonly id?: string;
    /**
     * 
     * @type {NotNestedPublicActivity}
     * @memberof PatchedNotNestedEventWithNestedActivity
     */
    activity?: NotNestedPublicActivity;
    /**
     * 
     * @type {Date}
     * @memberof PatchedNotNestedEventWithNestedActivity
     */
    start_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatchedNotNestedEventWithNestedActivity
     */
    end_date?: Date;
    /**
     * Must not be null, a Track must be linked to a meeting
     * @type {string}
     * @memberof PatchedNotNestedEventWithNestedActivity
     */
    readonly meeting_id?: string;
    /**
     * Room where is located the event ; can be empty if no room has been defined yet during the meeting
     * @type {string}
     * @memberof PatchedNotNestedEventWithNestedActivity
     */
    room?: string | null;
    /**
     * Participant team in charge of this event, can be empty if no team in charge
     * @type {string}
     * @memberof PatchedNotNestedEventWithNestedActivity
     */
    team_in_charge?: string | null;
    /**
     * 
     * @type {EventTypeEnum}
     * @memberof PatchedNotNestedEventWithNestedActivity
     */
    event_type?: EventTypeEnum;
    /**
     * Commentaire interne en équipe de formation
     * @type {string}
     * @memberof PatchedNotNestedEventWithNestedActivity
     */
    comment?: string;
    /**
     * Supervisors in charge to prepare the Event, can be empty if no supervisor defined (all supervisors or no-one)
     * @type {Array<string>}
     * @memberof PatchedNotNestedEventWithNestedActivity
     */
    supervisors?: Array<string>;
    /**
     * 
     * @type {EventVisibilityEnum}
     * @memberof PatchedNotNestedEventWithNestedActivity
     */
    visibility?: EventVisibilityEnum;
}



/**
 * Check if a given object implements the PatchedNotNestedEventWithNestedActivity interface.
 */
export function instanceOfPatchedNotNestedEventWithNestedActivity(value: object): value is PatchedNotNestedEventWithNestedActivity {
    return true;
}

export function PatchedNotNestedEventWithNestedActivityFromJSON(json: any): PatchedNotNestedEventWithNestedActivity {
    return PatchedNotNestedEventWithNestedActivityFromJSONTyped(json, false);
}

export function PatchedNotNestedEventWithNestedActivityFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedNotNestedEventWithNestedActivity {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'activity': json['activity'] == null ? undefined : NotNestedPublicActivityFromJSON(json['activity']),
        'start_date': json['start_date'] == null ? undefined : (new Date(json['start_date'])),
        'end_date': json['end_date'] == null ? undefined : (new Date(json['end_date'])),
        'meeting_id': json['meeting_id'] == null ? undefined : json['meeting_id'],
        'room': json['room'] == null ? undefined : json['room'],
        'team_in_charge': json['team_in_charge'] == null ? undefined : json['team_in_charge'],
        'event_type': json['event_type'] == null ? undefined : EventTypeEnumFromJSON(json['event_type']),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'supervisors': json['supervisors'] == null ? undefined : json['supervisors'],
        'visibility': json['visibility'] == null ? undefined : EventVisibilityEnumFromJSON(json['visibility']),
    };
}

export function PatchedNotNestedEventWithNestedActivityToJSON(json: any): PatchedNotNestedEventWithNestedActivity {
    return PatchedNotNestedEventWithNestedActivityToJSONTyped(json, false);
}

export function PatchedNotNestedEventWithNestedActivityToJSONTyped(value?: Omit<PatchedNotNestedEventWithNestedActivity, 'id'|'meeting_id'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'activity': NotNestedPublicActivityToJSON(value['activity']),
        'start_date': value['start_date'] == null ? undefined : ((value['start_date']).toISOString()),
        'end_date': value['end_date'] == null ? undefined : ((value['end_date']).toISOString()),
        'room': value['room'],
        'team_in_charge': value['team_in_charge'],
        'event_type': EventTypeEnumToJSON(value['event_type']),
        'comment': value['comment'],
        'supervisors': value['supervisors'],
        'visibility': EventVisibilityEnumToJSON(value['visibility']),
    };
}

