/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateNotNestedResource,
  CreatePublicNotNestedResource,
  NotNestedResource,
  PatchedNotNestedResource,
  PatchedPublicNotNestedResource,
  PublicNotNestedResource,
  PublicResourceTreeList,
  ResourceTreeList,
  ResourceTypeEnum,
  ResourceVisibilityEnum,
} from '../models/index';
import {
    CreateNotNestedResourceFromJSON,
    CreateNotNestedResourceToJSON,
    CreatePublicNotNestedResourceFromJSON,
    CreatePublicNotNestedResourceToJSON,
    NotNestedResourceFromJSON,
    NotNestedResourceToJSON,
    PatchedNotNestedResourceFromJSON,
    PatchedNotNestedResourceToJSON,
    PatchedPublicNotNestedResourceFromJSON,
    PatchedPublicNotNestedResourceToJSON,
    PublicNotNestedResourceFromJSON,
    PublicNotNestedResourceToJSON,
    PublicResourceTreeListFromJSON,
    PublicResourceTreeListToJSON,
    ResourceTreeListFromJSON,
    ResourceTreeListToJSON,
    ResourceTypeEnumFromJSON,
    ResourceTypeEnumToJSON,
    ResourceVisibilityEnumFromJSON,
    ResourceVisibilityEnumToJSON,
} from '../models/index';

export interface MeetingsResourcesCreateRequest {
    meetingUuid: string;
    createNotNestedResource: CreateNotNestedResource;
}

export interface MeetingsResourcesDestroyRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsResourcesPartialUpdateRequest {
    meetingUuid: string;
    uuid: string;
    patchedNotNestedResource?: Omit<PatchedNotNestedResource, 'media_url'|'media_available'>;
}

export interface MeetingsResourcesPublicCreateRequest {
    meetingUuid: string;
    createPublicNotNestedResource: CreatePublicNotNestedResource;
}

export interface MeetingsResourcesPublicPartialUpdateRequest {
    meetingUuid: string;
    uuid: string;
    patchedPublicNotNestedResource?: Omit<PatchedPublicNotNestedResource, 'media_url'|'media_available'>;
}

export interface MeetingsResourcesPublicResourceMediaRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsResourcesPublicResourceMediaUpdateRequest {
    meetingUuid: string;
    uuid: string;
    label: string;
    resourceType: ResourceTypeEnum;
    order: number;
    mediaUrl: string;
    mediaAvailable: boolean;
    id?: string;
    description?: string | null;
    url?: string | null;
    content?: string | null;
    trusted?: boolean;
    publicationDate?: Date | null;
    parent?: string | null;
    unpublish?: boolean;
    visibility?: ResourceVisibilityEnum;
}

export interface MeetingsResourcesPublicRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsResourcesPublicTreeRetrieveRequest {
    meetingUuid: string;
}

export interface MeetingsResourcesPublicUpdateRequest {
    meetingUuid: string;
    uuid: string;
    createPublicNotNestedResource: CreatePublicNotNestedResource;
}

export interface MeetingsResourcesResourceMediaRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsResourcesResourceMediaUpdateRequest {
    meetingUuid: string;
    uuid: string;
    label: string;
    resourceType: ResourceTypeEnum;
    order: number;
    mediaUrl: string;
    mediaAvailable: boolean;
    id?: string;
    description?: string | null;
    url?: string | null;
    content?: string | null;
    trusted?: boolean;
    publicationDate?: Date | null;
    parent?: string | null;
    unpublish?: boolean;
    visibility?: ResourceVisibilityEnum;
}

export interface MeetingsResourcesRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsResourcesTreeRetrieveRequest {
    meetingUuid: string;
}

export interface MeetingsResourcesUpdateRequest {
    meetingUuid: string;
    uuid: string;
    createNotNestedResource: CreateNotNestedResource;
}

/**
 * 
 */
export class ResourcesApi extends runtime.BaseAPI {

    /**
     * TODO
     */
    async meetingsResourcesCreateRaw(requestParameters: MeetingsResourcesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicNotNestedResource>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsResourcesCreate().'
            );
        }

        if (requestParameters['createNotNestedResource'] == null) {
            throw new runtime.RequiredError(
                'createNotNestedResource',
                'Required parameter "createNotNestedResource" was null or undefined when calling meetingsResourcesCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/resources/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateNotNestedResourceToJSON(requestParameters['createNotNestedResource']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicNotNestedResourceFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsResourcesCreate(requestParameters: MeetingsResourcesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicNotNestedResource> {
        const response = await this.meetingsResourcesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async meetingsResourcesDestroyRaw(requestParameters: MeetingsResourcesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsResourcesDestroy().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsResourcesDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/resources/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async meetingsResourcesDestroy(requestParameters: MeetingsResourcesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsResourcesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * TODO
     */
    async meetingsResourcesPartialUpdateRaw(requestParameters: MeetingsResourcesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedResource>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsResourcesPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsResourcesPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/resources/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedNotNestedResourceToJSON(requestParameters['patchedNotNestedResource']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedResourceFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsResourcesPartialUpdate(requestParameters: MeetingsResourcesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedResource> {
        const response = await this.meetingsResourcesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsResourcesPublicCreateRaw(requestParameters: MeetingsResourcesPublicCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicNotNestedResource>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsResourcesPublicCreate().'
            );
        }

        if (requestParameters['createPublicNotNestedResource'] == null) {
            throw new runtime.RequiredError(
                'createPublicNotNestedResource',
                'Required parameter "createPublicNotNestedResource" was null or undefined when calling meetingsResourcesPublicCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/resources-public/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePublicNotNestedResourceToJSON(requestParameters['createPublicNotNestedResource']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicNotNestedResourceFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsResourcesPublicCreate(requestParameters: MeetingsResourcesPublicCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicNotNestedResource> {
        const response = await this.meetingsResourcesPublicCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsResourcesPublicPartialUpdateRaw(requestParameters: MeetingsResourcesPublicPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicNotNestedResource>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsResourcesPublicPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsResourcesPublicPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/resources-public/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedPublicNotNestedResourceToJSON(requestParameters['patchedPublicNotNestedResource']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicNotNestedResourceFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsResourcesPublicPartialUpdate(requestParameters: MeetingsResourcesPublicPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicNotNestedResource> {
        const response = await this.meetingsResourcesPublicPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async meetingsResourcesPublicResourceMediaRetrieveRaw(requestParameters: MeetingsResourcesPublicResourceMediaRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicNotNestedResource>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsResourcesPublicResourceMediaRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsResourcesPublicResourceMediaRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/resources-public/{uuid}/resource-media/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicNotNestedResourceFromJSON(jsonValue));
    }

    /**
     */
    async meetingsResourcesPublicResourceMediaRetrieve(requestParameters: MeetingsResourcesPublicResourceMediaRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicNotNestedResource> {
        const response = await this.meetingsResourcesPublicResourceMediaRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async meetingsResourcesPublicResourceMediaUpdateRaw(requestParameters: MeetingsResourcesPublicResourceMediaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicNotNestedResource>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsResourcesPublicResourceMediaUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsResourcesPublicResourceMediaUpdate().'
            );
        }

        if (requestParameters['label'] == null) {
            throw new runtime.RequiredError(
                'label',
                'Required parameter "label" was null or undefined when calling meetingsResourcesPublicResourceMediaUpdate().'
            );
        }

        if (requestParameters['resourceType'] == null) {
            throw new runtime.RequiredError(
                'resourceType',
                'Required parameter "resourceType" was null or undefined when calling meetingsResourcesPublicResourceMediaUpdate().'
            );
        }

        if (requestParameters['order'] == null) {
            throw new runtime.RequiredError(
                'order',
                'Required parameter "order" was null or undefined when calling meetingsResourcesPublicResourceMediaUpdate().'
            );
        }

        if (requestParameters['mediaUrl'] == null) {
            throw new runtime.RequiredError(
                'mediaUrl',
                'Required parameter "mediaUrl" was null or undefined when calling meetingsResourcesPublicResourceMediaUpdate().'
            );
        }

        if (requestParameters['mediaAvailable'] == null) {
            throw new runtime.RequiredError(
                'mediaAvailable',
                'Required parameter "mediaAvailable" was null or undefined when calling meetingsResourcesPublicResourceMediaUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['id'] != null) {
            formParams.append('id', requestParameters['id'] as any);
        }

        if (requestParameters['label'] != null) {
            formParams.append('label', requestParameters['label'] as any);
        }

        if (requestParameters['description'] != null) {
            formParams.append('description', requestParameters['description'] as any);
        }

        if (requestParameters['resourceType'] != null) {
            formParams.append('resource_type', requestParameters['resourceType'] as any);
        }

        if (requestParameters['url'] != null) {
            formParams.append('url', requestParameters['url'] as any);
        }

        if (requestParameters['content'] != null) {
            formParams.append('content', requestParameters['content'] as any);
        }

        if (requestParameters['trusted'] != null) {
            formParams.append('trusted', requestParameters['trusted'] as any);
        }

        if (requestParameters['publicationDate'] != null) {
            formParams.append('publication_date', (requestParameters['publicationDate'] as any).toISOString());
        }

        if (requestParameters['order'] != null) {
            formParams.append('order', requestParameters['order'] as any);
        }

        if (requestParameters['parent'] != null) {
            formParams.append('parent', requestParameters['parent'] as any);
        }

        if (requestParameters['unpublish'] != null) {
            formParams.append('unpublish', requestParameters['unpublish'] as any);
        }

        if (requestParameters['visibility'] != null) {
            formParams.append('visibility', requestParameters['visibility'] as any);
        }

        if (requestParameters['mediaUrl'] != null) {
            formParams.append('media_url', requestParameters['mediaUrl'] as any);
        }

        if (requestParameters['mediaAvailable'] != null) {
            formParams.append('media_available', requestParameters['mediaAvailable'] as any);
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/resources-public/{uuid}/resource-media/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicNotNestedResourceFromJSON(jsonValue));
    }

    /**
     */
    async meetingsResourcesPublicResourceMediaUpdate(requestParameters: MeetingsResourcesPublicResourceMediaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicNotNestedResource> {
        const response = await this.meetingsResourcesPublicResourceMediaUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsResourcesPublicRetrieveRaw(requestParameters: MeetingsResourcesPublicRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicNotNestedResource>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsResourcesPublicRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsResourcesPublicRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/resources-public/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicNotNestedResourceFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsResourcesPublicRetrieve(requestParameters: MeetingsResourcesPublicRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicNotNestedResource> {
        const response = await this.meetingsResourcesPublicRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async meetingsResourcesPublicTreeRetrieveRaw(requestParameters: MeetingsResourcesPublicTreeRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicResourceTreeList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsResourcesPublicTreeRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/resources-public/tree/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicResourceTreeListFromJSON(jsonValue));
    }

    /**
     */
    async meetingsResourcesPublicTreeRetrieve(requestParameters: MeetingsResourcesPublicTreeRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicResourceTreeList> {
        const response = await this.meetingsResourcesPublicTreeRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsResourcesPublicUpdateRaw(requestParameters: MeetingsResourcesPublicUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreatePublicNotNestedResource>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsResourcesPublicUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsResourcesPublicUpdate().'
            );
        }

        if (requestParameters['createPublicNotNestedResource'] == null) {
            throw new runtime.RequiredError(
                'createPublicNotNestedResource',
                'Required parameter "createPublicNotNestedResource" was null or undefined when calling meetingsResourcesPublicUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/resources-public/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePublicNotNestedResourceToJSON(requestParameters['createPublicNotNestedResource']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePublicNotNestedResourceFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsResourcesPublicUpdate(requestParameters: MeetingsResourcesPublicUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreatePublicNotNestedResource> {
        const response = await this.meetingsResourcesPublicUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async meetingsResourcesResourceMediaRetrieveRaw(requestParameters: MeetingsResourcesResourceMediaRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedResource>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsResourcesResourceMediaRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsResourcesResourceMediaRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/resources/{uuid}/resource-media/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedResourceFromJSON(jsonValue));
    }

    /**
     */
    async meetingsResourcesResourceMediaRetrieve(requestParameters: MeetingsResourcesResourceMediaRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedResource> {
        const response = await this.meetingsResourcesResourceMediaRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async meetingsResourcesResourceMediaUpdateRaw(requestParameters: MeetingsResourcesResourceMediaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedResource>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsResourcesResourceMediaUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsResourcesResourceMediaUpdate().'
            );
        }

        if (requestParameters['label'] == null) {
            throw new runtime.RequiredError(
                'label',
                'Required parameter "label" was null or undefined when calling meetingsResourcesResourceMediaUpdate().'
            );
        }

        if (requestParameters['resourceType'] == null) {
            throw new runtime.RequiredError(
                'resourceType',
                'Required parameter "resourceType" was null or undefined when calling meetingsResourcesResourceMediaUpdate().'
            );
        }

        if (requestParameters['order'] == null) {
            throw new runtime.RequiredError(
                'order',
                'Required parameter "order" was null or undefined when calling meetingsResourcesResourceMediaUpdate().'
            );
        }

        if (requestParameters['mediaUrl'] == null) {
            throw new runtime.RequiredError(
                'mediaUrl',
                'Required parameter "mediaUrl" was null or undefined when calling meetingsResourcesResourceMediaUpdate().'
            );
        }

        if (requestParameters['mediaAvailable'] == null) {
            throw new runtime.RequiredError(
                'mediaAvailable',
                'Required parameter "mediaAvailable" was null or undefined when calling meetingsResourcesResourceMediaUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['id'] != null) {
            formParams.append('id', requestParameters['id'] as any);
        }

        if (requestParameters['label'] != null) {
            formParams.append('label', requestParameters['label'] as any);
        }

        if (requestParameters['description'] != null) {
            formParams.append('description', requestParameters['description'] as any);
        }

        if (requestParameters['resourceType'] != null) {
            formParams.append('resource_type', requestParameters['resourceType'] as any);
        }

        if (requestParameters['url'] != null) {
            formParams.append('url', requestParameters['url'] as any);
        }

        if (requestParameters['content'] != null) {
            formParams.append('content', requestParameters['content'] as any);
        }

        if (requestParameters['trusted'] != null) {
            formParams.append('trusted', requestParameters['trusted'] as any);
        }

        if (requestParameters['publicationDate'] != null) {
            formParams.append('publication_date', (requestParameters['publicationDate'] as any).toISOString());
        }

        if (requestParameters['order'] != null) {
            formParams.append('order', requestParameters['order'] as any);
        }

        if (requestParameters['parent'] != null) {
            formParams.append('parent', requestParameters['parent'] as any);
        }

        if (requestParameters['unpublish'] != null) {
            formParams.append('unpublish', requestParameters['unpublish'] as any);
        }

        if (requestParameters['visibility'] != null) {
            formParams.append('visibility', requestParameters['visibility'] as any);
        }

        if (requestParameters['mediaUrl'] != null) {
            formParams.append('media_url', requestParameters['mediaUrl'] as any);
        }

        if (requestParameters['mediaAvailable'] != null) {
            formParams.append('media_available', requestParameters['mediaAvailable'] as any);
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/resources/{uuid}/resource-media/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedResourceFromJSON(jsonValue));
    }

    /**
     */
    async meetingsResourcesResourceMediaUpdate(requestParameters: MeetingsResourcesResourceMediaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedResource> {
        const response = await this.meetingsResourcesResourceMediaUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsResourcesRetrieveRaw(requestParameters: MeetingsResourcesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedResource>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsResourcesRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsResourcesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/resources/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedResourceFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsResourcesRetrieve(requestParameters: MeetingsResourcesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedResource> {
        const response = await this.meetingsResourcesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async meetingsResourcesTreeRetrieveRaw(requestParameters: MeetingsResourcesTreeRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceTreeList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsResourcesTreeRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/resources/tree/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceTreeListFromJSON(jsonValue));
    }

    /**
     */
    async meetingsResourcesTreeRetrieve(requestParameters: MeetingsResourcesTreeRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceTreeList> {
        const response = await this.meetingsResourcesTreeRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TODO
     */
    async meetingsResourcesUpdateRaw(requestParameters: MeetingsResourcesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateNotNestedResource>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsResourcesUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsResourcesUpdate().'
            );
        }

        if (requestParameters['createNotNestedResource'] == null) {
            throw new runtime.RequiredError(
                'createNotNestedResource',
                'Required parameter "createNotNestedResource" was null or undefined when calling meetingsResourcesUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/resources/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateNotNestedResourceToJSON(requestParameters['createNotNestedResource']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateNotNestedResourceFromJSON(jsonValue));
    }

    /**
     * TODO
     */
    async meetingsResourcesUpdate(requestParameters: MeetingsResourcesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateNotNestedResource> {
        const response = await this.meetingsResourcesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
