/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `ALL` - all
 * * `SUPERVISORS` - supervisors
 * @export
 */
export const ResourceVisibilityEnum = {
    All: 'ALL',
    Supervisors: 'SUPERVISORS'
} as const;
export type ResourceVisibilityEnum = typeof ResourceVisibilityEnum[keyof typeof ResourceVisibilityEnum];


export function instanceOfResourceVisibilityEnum(value: any): boolean {
    for (const key in ResourceVisibilityEnum) {
        if (Object.prototype.hasOwnProperty.call(ResourceVisibilityEnum, key)) {
            if (ResourceVisibilityEnum[key as keyof typeof ResourceVisibilityEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ResourceVisibilityEnumFromJSON(json: any): ResourceVisibilityEnum {
    return ResourceVisibilityEnumFromJSONTyped(json, false);
}

export function ResourceVisibilityEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceVisibilityEnum {
    return json as ResourceVisibilityEnum;
}

export function ResourceVisibilityEnumToJSON(value?: ResourceVisibilityEnum | null): any {
    return value as any;
}

export function ResourceVisibilityEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): ResourceVisibilityEnum {
    return value as ResourceVisibilityEnum;
}

