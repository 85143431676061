/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ResourceTypeEnum } from './ResourceTypeEnum';
import {
    ResourceTypeEnumFromJSON,
    ResourceTypeEnumFromJSONTyped,
    ResourceTypeEnumToJSON,
    ResourceTypeEnumToJSONTyped,
} from './ResourceTypeEnum';
import type { ResourceVisibilityEnum } from './ResourceVisibilityEnum';
import {
    ResourceVisibilityEnumFromJSON,
    ResourceVisibilityEnumFromJSONTyped,
    ResourceVisibilityEnumToJSON,
    ResourceVisibilityEnumToJSONTyped,
} from './ResourceVisibilityEnum';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface CreatePublicNotNestedResource
 */
export interface CreatePublicNotNestedResource {
    /**
     * 
     * @type {string}
     * @memberof CreatePublicNotNestedResource
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicNotNestedResource
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicNotNestedResource
     */
    description?: string | null;
    /**
     * Type de diplôme
     * 
     * * `content` - Contenu
     * * `link` - Lien
     * * `media` - Média
     * * `category` - Catégorie
     * @type {ResourceTypeEnum}
     * @memberof CreatePublicNotNestedResource
     */
    resource_type: ResourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicNotNestedResource
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicNotNestedResource
     */
    content?: string | null;
    /**
     * La ressource a-t-elle été créée par l'équipe de formation
     * @type {boolean}
     * @memberof CreatePublicNotNestedResource
     */
    trusted?: boolean;
    /**
     * Date de publication de la resource
     * @type {Date}
     * @memberof CreatePublicNotNestedResource
     */
    publication_date?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof CreatePublicNotNestedResource
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicNotNestedResource
     */
    parent?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePublicNotNestedResource
     */
    unpublish?: boolean;
    /**
     * 
     * @type {ResourceVisibilityEnum}
     * @memberof CreatePublicNotNestedResource
     */
    visibility?: ResourceVisibilityEnum;
}



/**
 * Check if a given object implements the CreatePublicNotNestedResource interface.
 */
export function instanceOfCreatePublicNotNestedResource(value: object): value is CreatePublicNotNestedResource {
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('resource_type' in value) || value['resource_type'] === undefined) return false;
    if (!('order' in value) || value['order'] === undefined) return false;
    return true;
}

export function CreatePublicNotNestedResourceFromJSON(json: any): CreatePublicNotNestedResource {
    return CreatePublicNotNestedResourceFromJSONTyped(json, false);
}

export function CreatePublicNotNestedResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePublicNotNestedResource {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'label': json['label'],
        'description': json['description'] == null ? undefined : json['description'],
        'resource_type': ResourceTypeEnumFromJSON(json['resource_type']),
        'url': json['url'] == null ? undefined : json['url'],
        'content': json['content'] == null ? undefined : json['content'],
        'trusted': json['trusted'] == null ? undefined : json['trusted'],
        'publication_date': json['publication_date'] == null ? undefined : (new Date(json['publication_date'])),
        'order': json['order'],
        'parent': json['parent'] == null ? undefined : json['parent'],
        'unpublish': json['unpublish'] == null ? undefined : json['unpublish'],
        'visibility': json['visibility'] == null ? undefined : ResourceVisibilityEnumFromJSON(json['visibility']),
    };
}

export function CreatePublicNotNestedResourceToJSON(json: any): CreatePublicNotNestedResource {
    return CreatePublicNotNestedResourceToJSONTyped(json, false);
}

export function CreatePublicNotNestedResourceToJSONTyped(value?: CreatePublicNotNestedResource | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'label': value['label'],
        'description': value['description'],
        'resource_type': ResourceTypeEnumToJSON(value['resource_type']),
        'url': value['url'],
        'content': value['content'],
        'trusted': value['trusted'],
        'publication_date': value['publication_date'] == null ? undefined : ((value['publication_date'] as any).toISOString()),
        'order': value['order'],
        'parent': value['parent'],
        'unpublish': value['unpublish'],
        'visibility': ResourceVisibilityEnumToJSON(value['visibility']),
    };
}

