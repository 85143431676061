/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface DuplicationMeetingForm
 */
export interface DuplicationMeetingForm {
    /**
     * 
     * @type {string}
     * @memberof DuplicationMeetingForm
     */
    title?: string;
}

/**
 * Check if a given object implements the DuplicationMeetingForm interface.
 */
export function instanceOfDuplicationMeetingForm(value: object): value is DuplicationMeetingForm {
    return true;
}

export function DuplicationMeetingFormFromJSON(json: any): DuplicationMeetingForm {
    return DuplicationMeetingFormFromJSONTyped(json, false);
}

export function DuplicationMeetingFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): DuplicationMeetingForm {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'] == null ? undefined : json['title'],
    };
}

export function DuplicationMeetingFormToJSON(json: any): DuplicationMeetingForm {
    return DuplicationMeetingFormToJSONTyped(json, false);
}

export function DuplicationMeetingFormToJSONTyped(value?: DuplicationMeetingForm | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'title': value['title'],
    };
}

