/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `ALL` - all
 * * `TEAM` - team
 * * `SUPERVISORS` - supervisors
 * @export
 */
export const EventVisibilityEnum = {
    All: 'ALL',
    Team: 'TEAM',
    Supervisors: 'SUPERVISORS'
} as const;
export type EventVisibilityEnum = typeof EventVisibilityEnum[keyof typeof EventVisibilityEnum];


export function instanceOfEventVisibilityEnum(value: any): boolean {
    for (const key in EventVisibilityEnum) {
        if (Object.prototype.hasOwnProperty.call(EventVisibilityEnum, key)) {
            if (EventVisibilityEnum[key as keyof typeof EventVisibilityEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function EventVisibilityEnumFromJSON(json: any): EventVisibilityEnum {
    return EventVisibilityEnumFromJSONTyped(json, false);
}

export function EventVisibilityEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventVisibilityEnum {
    return json as EventVisibilityEnum;
}

export function EventVisibilityEnumToJSON(value?: EventVisibilityEnum | null): any {
    return value as any;
}

export function EventVisibilityEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): EventVisibilityEnum {
    return value as EventVisibilityEnum;
}

